import { default as _91_46_46_46slug_93mThPb9biDoMeta } from "/vercel/path0/apps/web/pages/[...slug].vue?macro=true";
import { default as i18n_45test850Asn67zgMeta } from "/vercel/path0/apps/web/pages/dev/i18n-test.vue?macro=true";
import { default as sanity_45test2LdNoHmtMIMeta } from "/vercel/path0/apps/web/pages/dev/sanity-test.vue?macro=true";
import { default as styleguidetYjqLPfoDgMeta } from "/vercel/path0/apps/web/pages/dev/styleguide.vue?macro=true";
import { default as testpage7OcYLzmU4CMeta } from "/vercel/path0/apps/web/pages/dev/testpage.vue?macro=true";
import { default as nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEVMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_xiss2qqn2g657l564gfznel27y/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEV } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_xiss2qqn2g657l564gfznel27y/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___en-gb___default",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___en-gb",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/en-gb/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___en-au",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/en-au/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___de-at",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/de-at/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___nl-be",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/nl-be/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___fr-be",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/fr-be/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___en-ca",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/en-ca/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___fr-ca",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/fr-ca/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___cs-cz",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/cs-cz/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___da",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/da/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___fr",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___en-ie",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/en-ie/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___it",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/it/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___no",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/no/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___pl",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/pl/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___pt",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/pt/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___es",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___sv",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/sv/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___de-ch",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/de-ch/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___fr-ch",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/fr-ch/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___it-ch",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/it-ch/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___nl",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/nl/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mThPb9biDoMeta?.name ?? "slug___en-us",
    path: _91_46_46_46slug_93mThPb9biDoMeta?.path ?? "/en-us/:slug(.*)*",
    meta: _91_46_46_46slug_93mThPb9biDoMeta || {},
    alias: _91_46_46_46slug_93mThPb9biDoMeta?.alias || [],
    redirect: _91_46_46_46slug_93mThPb9biDoMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___en-gb___default",
    path: i18n_45test850Asn67zgMeta?.path ?? "/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___en-gb",
    path: i18n_45test850Asn67zgMeta?.path ?? "/en-gb/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___en-au",
    path: i18n_45test850Asn67zgMeta?.path ?? "/en-au/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___de-at",
    path: i18n_45test850Asn67zgMeta?.path ?? "/de-at/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___nl-be",
    path: i18n_45test850Asn67zgMeta?.path ?? "/nl-be/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___fr-be",
    path: i18n_45test850Asn67zgMeta?.path ?? "/fr-be/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___en-ca",
    path: i18n_45test850Asn67zgMeta?.path ?? "/en-ca/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___fr-ca",
    path: i18n_45test850Asn67zgMeta?.path ?? "/fr-ca/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___cs-cz",
    path: i18n_45test850Asn67zgMeta?.path ?? "/cs-cz/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___da",
    path: i18n_45test850Asn67zgMeta?.path ?? "/da/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___fr",
    path: i18n_45test850Asn67zgMeta?.path ?? "/fr/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___de",
    path: i18n_45test850Asn67zgMeta?.path ?? "/de/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___en-ie",
    path: i18n_45test850Asn67zgMeta?.path ?? "/en-ie/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___it",
    path: i18n_45test850Asn67zgMeta?.path ?? "/it/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___no",
    path: i18n_45test850Asn67zgMeta?.path ?? "/no/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___pl",
    path: i18n_45test850Asn67zgMeta?.path ?? "/pl/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___pt",
    path: i18n_45test850Asn67zgMeta?.path ?? "/pt/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___es",
    path: i18n_45test850Asn67zgMeta?.path ?? "/es/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___sv",
    path: i18n_45test850Asn67zgMeta?.path ?? "/sv/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___de-ch",
    path: i18n_45test850Asn67zgMeta?.path ?? "/de-ch/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___fr-ch",
    path: i18n_45test850Asn67zgMeta?.path ?? "/fr-ch/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___it-ch",
    path: i18n_45test850Asn67zgMeta?.path ?? "/it-ch/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___nl",
    path: i18n_45test850Asn67zgMeta?.path ?? "/nl/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: i18n_45test850Asn67zgMeta?.name ?? "dev-i18n-test___en-us",
    path: i18n_45test850Asn67zgMeta?.path ?? "/en-us/dev/i18n-test",
    meta: i18n_45test850Asn67zgMeta || {},
    alias: i18n_45test850Asn67zgMeta?.alias || [],
    redirect: i18n_45test850Asn67zgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/i18n-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___en-gb___default",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___en-gb",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/en-gb/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___en-au",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/en-au/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___de-at",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/de-at/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___nl-be",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/nl-be/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___fr-be",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/fr-be/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___en-ca",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/en-ca/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___fr-ca",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/fr-ca/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___cs-cz",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/cs-cz/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___da",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/da/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___fr",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/fr/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___de",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/de/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___en-ie",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/en-ie/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___it",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/it/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___no",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/no/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___pl",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/pl/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___pt",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/pt/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___es",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/es/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___sv",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/sv/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___de-ch",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/de-ch/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___fr-ch",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/fr-ch/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___it-ch",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/it-ch/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___nl",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/nl/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: sanity_45test2LdNoHmtMIMeta?.name ?? "dev-sanity-test___en-us",
    path: sanity_45test2LdNoHmtMIMeta?.path ?? "/en-us/dev/sanity-test",
    meta: sanity_45test2LdNoHmtMIMeta || {},
    alias: sanity_45test2LdNoHmtMIMeta?.alias || [],
    redirect: sanity_45test2LdNoHmtMIMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/sanity-test.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___en-gb___default",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___en-gb",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/en-gb/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___en-au",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/en-au/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___de-at",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/de-at/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___nl-be",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/nl-be/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___fr-be",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/fr-be/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___en-ca",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/en-ca/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___fr-ca",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/fr-ca/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___cs-cz",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/cs-cz/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___da",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/da/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___fr",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/fr/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___de",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/de/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___en-ie",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/en-ie/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___it",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/it/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___no",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/no/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___pl",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/pl/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___pt",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/pt/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___es",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/es/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___sv",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/sv/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___de-ch",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/de-ch/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___fr-ch",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/fr-ch/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___it-ch",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/it-ch/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___nl",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/nl/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: styleguidetYjqLPfoDgMeta?.name ?? "dev-styleguide___en-us",
    path: styleguidetYjqLPfoDgMeta?.path ?? "/en-us/dev/styleguide",
    meta: styleguidetYjqLPfoDgMeta || {},
    alias: styleguidetYjqLPfoDgMeta?.alias || [],
    redirect: styleguidetYjqLPfoDgMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/styleguide.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___en-gb___default",
    path: testpage7OcYLzmU4CMeta?.path ?? "/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___en-gb",
    path: testpage7OcYLzmU4CMeta?.path ?? "/en-gb/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___en-au",
    path: testpage7OcYLzmU4CMeta?.path ?? "/en-au/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___de-at",
    path: testpage7OcYLzmU4CMeta?.path ?? "/de-at/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___nl-be",
    path: testpage7OcYLzmU4CMeta?.path ?? "/nl-be/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___fr-be",
    path: testpage7OcYLzmU4CMeta?.path ?? "/fr-be/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___en-ca",
    path: testpage7OcYLzmU4CMeta?.path ?? "/en-ca/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___fr-ca",
    path: testpage7OcYLzmU4CMeta?.path ?? "/fr-ca/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___cs-cz",
    path: testpage7OcYLzmU4CMeta?.path ?? "/cs-cz/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___da",
    path: testpage7OcYLzmU4CMeta?.path ?? "/da/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___fr",
    path: testpage7OcYLzmU4CMeta?.path ?? "/fr/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___de",
    path: testpage7OcYLzmU4CMeta?.path ?? "/de/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___en-ie",
    path: testpage7OcYLzmU4CMeta?.path ?? "/en-ie/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___it",
    path: testpage7OcYLzmU4CMeta?.path ?? "/it/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___no",
    path: testpage7OcYLzmU4CMeta?.path ?? "/no/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___pl",
    path: testpage7OcYLzmU4CMeta?.path ?? "/pl/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___pt",
    path: testpage7OcYLzmU4CMeta?.path ?? "/pt/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___es",
    path: testpage7OcYLzmU4CMeta?.path ?? "/es/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___sv",
    path: testpage7OcYLzmU4CMeta?.path ?? "/sv/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___de-ch",
    path: testpage7OcYLzmU4CMeta?.path ?? "/de-ch/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___fr-ch",
    path: testpage7OcYLzmU4CMeta?.path ?? "/fr-ch/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___it-ch",
    path: testpage7OcYLzmU4CMeta?.path ?? "/it-ch/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___nl",
    path: testpage7OcYLzmU4CMeta?.path ?? "/nl/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: testpage7OcYLzmU4CMeta?.name ?? "dev-testpage___en-us",
    path: testpage7OcYLzmU4CMeta?.path ?? "/en-us/dev/testpage",
    meta: testpage7OcYLzmU4CMeta || {},
    alias: testpage7OcYLzmU4CMeta?.alias || [],
    redirect: testpage7OcYLzmU4CMeta?.redirect,
    component: () => import("/vercel/path0/apps/web/pages/dev/testpage.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEVMeta?.name ?? undefined,
    path: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEVMeta?.path ?? "/sitemap.xml",
    meta: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEVMeta || {},
    alias: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEVMeta?.alias || [],
    redirect: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEVMeta?.redirect,
    component: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467_encoding_640_461_4613_eslint_648_4657RK880FudEV
  }
]